import { setLoading } from 'appRedux/reducers/Loading'
import {
  addPaginationItem,
  deletePaginationItem,
  pagePaginationSuccess,
  updatePaginationItem,
} from 'appRedux/reducers/Pagination'
import {
  AssetsService,
  CompanyService,
  EmployeeService,
  EprsService,
  HolidaysService,
  HRService,
  LeavesService,
  LoansService,
  OfficesService,
  PayScheduleService,
  ReportService,
  ShiftsService,
} from 'services'
import { ALERT_TYPES, PAGINATION_LIMIT, PAGINATION_TYPES } from 'util/constants'
import { showMessage } from 'util/helpers'
import { REST_API } from '../../services/rest.api'

export const fetchEmployees = ({ page = 1, limit, ...searchParams }, body, viewType) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.employees
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const {
        items,
        pagination: { self, last },
      } = getState().pagination[key]

      const res =
        (!(parseInt(self) === last) || Object.values(searchParams).length > 0) &&
        (await EmployeeService.search(
          {
            limit: limit || PAGINATION_LIMIT,
            page,
            sort: 'id,desc',
            ...searchParams,
          },
          body
        ))
      const allItems = viewType !== 'table' && page > 1 ? [...items, ...res.items] : res.items
      res.items = allItems
      dispatch(
        pagePaginationSuccess({
          data: res,
          key,
        })
      )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const addOrUpdateEmployee = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.employees
    const { id, ...rest } = payload
    let res
    rest.photo = payload.photo ? payload.photo.split(',')[1] : null
    if (!id) {
      res = await EmployeeService.create(rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
    } else {
      res = await EmployeeService.patch(id, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    return res
  }
}

export const deleteEmployee = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.employees
    const loadingObj = {
      key,
      value: true,
    }
    dispatch(setLoading(loadingObj))
    let res
    if (id) {
      try {
        res = await EmployeeService.remove({ employee_id: id })
        if (res?.success) {
          dispatch(
            deletePaginationItem({
              key,
              value: id,
            })
          )
          showMessage(ALERT_TYPES.SUCCESS, 'Employee has been successfully deleted!')
        }
      } catch (error) {
        // showMessage(ALERT_TYPES.ERROR, 'Unexpected error when Employee deleted!');
      }
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const fetchAssets = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.assets
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await AssetsService.find(id)
      dispatch(
        pagePaginationSuccess({
          data: { count: res.length, total: res.length, items: res.reverse() },
          key,
        })
      )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const addOrUpdateAsset = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.assets
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await AssetsService.create(rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
    } else {
      res = await AssetsService.patch(id, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    return res
  }
}

export const deleteAsset = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.assets
    const loadingObj = {
      key,
      value: true,
    }
    dispatch(setLoading(loadingObj))
    let res
    if (id) {
      res = await AssetsService.remove({ asset_id: id })
      if (res?.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const fetchLeaveTypes = () => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.leave_types
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await LeavesService.getItem(REST_API.LEAVE.TYPES)
      dispatch(
        pagePaginationSuccess({
          data: { count: res.length, total: res.length, items: res.reverse() },
          key,
        })
      )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const addOrUpdateLeaveType = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.leave_types
    const route = REST_API.LEAVE.TYPES
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await LeavesService.createItem(route, rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
    } else {
      res = await LeavesService.patchItem(id, route, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    return res
  }
}

export const deleteLeaveType = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.leave_types
    const route = REST_API.LEAVE.TYPES
    const loadingObj = {
      key,
      value: true,
    }
    dispatch(setLoading(loadingObj))
    let res
    if (id) {
      res = await LeavesService.removeItem(route, { type_id: id })
      if (res?.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const fetchDevelopmentArea = () => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.development_areas
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await EprsService.getItem(REST_API.EPRS.DEVELOPMENT_AREA)
      dispatch(
        pagePaginationSuccess({
          data: { count: res.length, total: res.length, items: res.reverse() },
          key,
        })
      )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}
export const addOrUpdateDevelopmentArea = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.development_areas
    const route = REST_API.EPRS.DEVELOPMENT_AREA
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await EprsService.createItem(route, rest)
      dispatch(
        addPaginationItem({
          value: res,
          key,
        })
      )
    } else {
      res = await EprsService.patchItem(id, route, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    return res
  }
}
export const deleteDevelopmentArea = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.development_areas
    const route = REST_API.EPRS.DEVELOPMENT_AREA
    const loadingObj = {
      key,
      value: true,
    }
    dispatch(setLoading(loadingObj))
    let res
    if (id) {
      res = await EprsService.removeItem(route, { development_area_id: id })
      if (res?.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const fetchDurations = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.leave_durations
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await LeavesService.getItem(REST_API.LEAVE.DURATIONS)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: { count: res.length, total: res.length, items: res.reverse() },
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}
export const addOrUpdateDuration = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.leave_durations
    const route = REST_API.LEAVE.DURATIONS
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await LeavesService.createItem(route, rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
    } else {
      res = await LeavesService.patchItem(id, route, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    return res
  }
}

export const deleteDuration = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.leave_durations
    const route = REST_API.LEAVE.DURATIONS
    const loadingObj = {
      key,
      value: true,
    }
    dispatch(setLoading(loadingObj))
    let res
    if (id) {
      res = await LeavesService.removeItem(route, { duration_id: id })
      if (res?.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const fetchCompanies = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.companies
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await CompanyService.find(id)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: { count: res.length, total: res.length, items: res.reverse() },
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const addOrUpdateCompany = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.companies
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await CompanyService.create(rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
    } else {
      res = await CompanyService.patch(id, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    return res
  }
}
export const deleteCompany = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.companies
    const loadingObj = {
      key,
      value: true,
    }
    dispatch(setLoading(loadingObj))
    let res
    if (id) {
      res = await CompanyService.remove({ company_id: id })
      if (res?.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const fetchHolidays = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.holidays
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await HolidaysService.find(query)
      dispatch(
        pagePaginationSuccess({
          data: { count: res.length, total: res.length, items: res.reverse() },
          key,
        })
      )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const addOrUpdateHoliday = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.holidays
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await HolidaysService.create(rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
    } else {
      res = await HolidaysService.patch(id, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    return res
  }
}

export const deleteHoliday = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.holidays
    const loadingObj = {
      key,
      value: true,
    }
    dispatch(setLoading(loadingObj))
    let res
    if (id) {
      res = await HolidaysService.remove({ holiday_id: id })
      if (res?.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const fetchAssignShifts = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.assign_shifts
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await EmployeeService.getItem(REST_API.EMPLOYEES_ROUTE.SHIFTS, query)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: res,
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const fetchShifts = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.shifts
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await ShiftsService.find(query)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: { count: res.length, total: res.length, items: res.reverse() },
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const addOrUpdateShift = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.shifts
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await ShiftsService.create(rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
    } else {
      res = await ShiftsService.patch(id, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    return res
  }
}

export const deleteShift = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.shifts
    const loadingObj = {
      key,
      value: true,
    }
    dispatch(setLoading(loadingObj))
    let res
    if (id) {
      res = await ShiftsService.remove({ shift_id: id })
      if (res?.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const fetchOffices = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.offices
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await OfficesService.find(query)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: { count: res.length, total: res.length, items: res.reverse() },
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const addOrUpdateOffices = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.offices
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await OfficesService.create(rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
    } else {
      res = await OfficesService.patch(id, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    return res
  }
}

export const deleteOffices = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.offices
    const loadingObj = {
      key,
      value: true,
    }
    dispatch(setLoading(loadingObj))
    let res
    if (id) {
      res = await OfficesService.remove({ office_id: id })
      if (res?.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const fetchPaySchedules = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.pay_schedules
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await PayScheduleService.find(query)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: res,
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}
export const addOrUpdatePaySchedules = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.pay_schedules
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await PayScheduleService.create(rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
    } else {
      res = await PayScheduleService.patch(id, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    return res
  }
}

export const deletePaySchedules = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.pay_schedules
    const loadingObj = {
      key,
      value: true,
    }
    // dispatch(setLoading(loadingObj));
    let res
    if (id) {
      res = await PayScheduleService.remove({ pay_schedule_id: id })
      if (res.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    // dispatch(setLoading(loadingObj));
    return res
  }
}
export const fetchLoans = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.loans
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await LoansService.find(query)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: res,
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const addOrUpdateLoans = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.loans
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await LoansService.create(rest)
      dispatch(
        addPaginationItem({
          value: res,
          key,
        })
      )
    } else {
      res = await LoansService.patch(id, rest)
      dispatch(
        updatePaginationItem({
          key,
          id,
          value: res,
        })
      )
    }
    return res
  }
}

export const deleteLoans = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.loans
    const loadingObj = {
      key,
      value: true,
    }
    // dispatch(setLoading(loadingObj));
    let res
    if (id) {
      res = await LoansService.remove({ loan_id: id })
      if (res.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    // dispatch(setLoading(loadingObj));
    return res
  }
}

export const fetchIndividualAttendance = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.attendance_individual
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await ReportService.getItem(REST_API.REPORT.ATTENDANCE_INDIVIDUAL, query)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: { count: res.length, total: res.length, items: res },
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const fetchSummaryAttendance = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.attendance_summary
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await ReportService.getItem(REST_API.REPORT.ATTENDANCE_SUMMARY, query)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: { count: res.length, total: res.length, items: res },
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const fetchHRS = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.hr_services
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await HRService.find(query)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: { ...res },
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const addOrUpdateHRS = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.hr_services
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await HRService.create(rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
    } else {
      res = await HRService.patch(id, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    return res
  }
}

export const deleteHRService = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.hr_services
    const loadingObj = {
      key,
      value: true,
    }
    let res
    if (id) {
      res = await HRService.remove({ service_request_id: id })
      if (res.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    return res
  }
}

export const handleHRServiceActions = (payload) => {
  return async (dispatch) => {
    const { id, key, route } = payload
    const loadingObj = {
      key,
      value: true,
    }
    if (id) {
      dispatch(setLoading(loadingObj))
      const res = await HRService.patchAction(route, { service_request_id: id })
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

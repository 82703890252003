import { Activity } from './activity.service'
import { Assets } from './assets.service'
import AuthenticationService from './authentication.service'
import { Company } from './company.service'
import { Employees } from './employees.service'
import { EPR } from './eprs.service'
import { Exams } from './exams.service'
import { Groups } from './groups.service'
import { Holidays } from './holidays.service'
import { HRServices } from './hrS.services'
import { KPI } from './kpis.service'
import { Leaves } from './leaves.service'
import { Loans } from './loans.service'
import { Objective } from './objective.service'
import { ObjectiveKPI } from './objectiveKpi.service'
import { Offices } from './offices.service'
import { PaySchedules } from './paySchedules.service'
import { PayScheduleType } from './payScheduleType.service'
import { RemoteWork } from './remoteWork.service'
import { Report } from './report.service'
import { Salary } from './salary.service'
import { Shifts } from './shifts.service'

import { REST_API } from './rest.api'

const AuthService = new AuthenticationService()
const AssetsService = new Assets(REST_API.assets)
const EmployeeService = new Employees(REST_API.employees)
const LeavesService = new Leaves(REST_API.leave)
const LeaveService = new Leaves(REST_API.leave)
const HRService = new HRServices(REST_API.serviceR)
const GroupsService = new Groups(REST_API.groups)
const ExamsService = new Exams(REST_API.exams)
const CompanyService = new Company(REST_API.companies)
const OfficesService = new Offices(REST_API.offices)
const ShiftsService = new Shifts(REST_API.shifts)
const HolidaysService = new Holidays(REST_API.holidays)
const RemoteWorkService = new RemoteWork(REST_API.remote_work)
const ReportService = new Report(REST_API.report)
const PayScheduleService = new PaySchedules(REST_API.pay_schedules)
const PayScheduleTypeService = new PayScheduleType(REST_API.pay_schedule_type)
const LoansService = new Loans(REST_API.loans)
const SalaryService = new Salary(REST_API.salaries)

const EprsService = new EPR(REST_API.eprs)

const EprService = new EPR(REST_API.eprs)
const KpiService = new KPI(REST_API.kpis)
const ObjectiveService = new Objective(REST_API.objective)
const ObjectiveKpiService = new ObjectiveKPI(REST_API.objective_kpi)
const ActivityService = new Activity(REST_API.activity)

export {
  ActivityService, AssetsService, AuthService, CompanyService, EmployeeService, EprService, EprsService, ExamsService, GroupsService, HolidaysService, HRService, KpiService, LeaveService, LeavesService, LoansService, ObjectiveKpiService, ObjectiveService, OfficesService, PayScheduleService,
  PayScheduleTypeService, RemoteWorkService, ReportService, SalaryService, ShiftsService
}


import { Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
//
import UserProfileImage from 'components/UserProfileImage';


const EmployeeImgForm = ({ form, employee_id }) => {

    return (
        <>
            <Row>
                <Col span={12}>
                    <Row
                        justify="center"
                        align="middle"
                    >
                        <Col>
                            <Form.Item
                                name='photo'
                                noStyle
                            >
                                <UserProfileImage />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

EmployeeImgForm.defaultProps = {
    form: null,
    isEdit: false,
    employee_id: null,
};

EmployeeImgForm.propTypes = {
    form: PropTypes.any,
    isEdit: PropTypes.bool,
    employee_id: PropTypes.number,
};

export default EmployeeImgForm;

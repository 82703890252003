import { Col, DatePicker, Form, Input, Modal, Row, Select, Spin } from 'antd'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { ALERT_TYPES } from 'util/constants'
import { showMessage } from 'util/helpers'

const FORM_ITEM_LAYOUT = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
}

const FormModal = ({
    visible,
    formName,
    width,
    handleCancel,
    handleOk,
    title,
    form,
    noc_type,
    category,
    selectedCategory,
    setSelectedCategory,
}) => {
    const { url } = useRouteMatch()
    const [loading, setLoading] = useState(false)

    const handleCategoryChange = (value) => {
        setSelectedCategory(value)
        form.setFieldsValue({
            noc_type_id: null,
        })
    }

    const onValuesChange = (values) => {
        Object.keys(values).forEach((field) => {
            const error = form.getFieldError(field)
            if (!error.length) {
                return
            }
            form.setFields([
                {
                    name: field,
                    errors: [],
                },
            ])
        })
    }

    const handleSubmit = async (value) => {
        setLoading(true)
        try {
            await handleOk(value)
            form.resetFields()
            handleCancel()
        } catch (error) {
            try {
                console.error('PRINT IN %s=====>', 'Form Submit First', error)
                const eRes = await error.response.json().then((res) => res)
                showMessage(ALERT_TYPES.ERROR, eRes.message)
                const errors = eRes.errors.children
                const data = []
                // eslint-disable-next-line no-restricted-syntax, guard-for-in
                for (const v in value) {
                    const i = {
                        name: v,
                        ...errors[v],
                    }
                    data.push(i)
                }
                form.setFields(data)
            } catch (error2) {
                console.error('PRINT IN %s=====>', 'Form Submit', error2)
                showMessage(ALERT_TYPES.WARN, 'Oops, Something Wrong!')
            }
        }
        setLoading(false)
    }

    return (
        <Modal
            visible={visible}
            width={width}
            title={title}
            okText="Save"
            cancelText="Cancel"
            confirmLoading={loading}
            destroyOnClose
            onCancel={() => {
                form.resetFields()
                handleCancel()
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        handleSubmit(values)
                    })
                    .catch((info) => {
                        console.error('Validate Failed:', info)
                    })
            }}
        >
            <Spin spinning={loading}>
                <Form
                    form={form} onValuesChange={onValuesChange} name={formName} layout="vertical"
                >
                    <Row>
                        <Col>
                            <Form.Item
                                {...FORM_ITEM_LAYOUT}
                                name="category"
                                label="Service"
                                rules={[{ required: true, message: 'Service must not be empty' }]}
                            >
                                <Select
                                    allowClear
                                    placeholder="Select service"
                                    style={{ width: '100%' }}
                                    onChange={handleCategoryChange}
                                >
                                    {category?.map((item, index) => (
                                        <Select.Option key={item.value} value={item.value}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {selectedCategory === 'noc' && (
                                <Form.Item
                                    {...FORM_ITEM_LAYOUT}
                                    name="noc_type_id"
                                    label="NOC Type"
                                    rules={[{ required: true, message: 'NOC Type must not be empty' }]}
                                >
                                    <Select
                                        allowClear placeholder="Select NOC Type" style={{ width: '100%' }}
                                    >
                                        {noc_type?.map((item, index) => (
                                            <Select.Option key={item?.id} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                            {selectedCategory !== 'noc' && (
                                <Form.Item
                                    {...FORM_ITEM_LAYOUT} name="reason" label="Reason" rules={[
                                        {
                                            required: true,
                                            message: 'Reason must not be empty',
                                        },
                                    ]}
                                >
                                    <Input allowClear placeholder="Please enter your reason" />
                                </Form.Item>
                            )}
                            {/* </Col> */}

                            {/* <Col span={8}> */}
                            {/* <Form.Item
                                {...FORM_ITEM_LAYOUT}
                                name="employee_id"
                                label="Employee"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Employee must not be empty',
                                    },
                                ]}
                            >
                                <EmployeeSearchBox
                                    value={search?.employee_id}
                                    onChange={(item) => handleChange(item?.value?.split('#')[0], 'employee_id')}
                                    placeholder="Search employee"
                                />
                            </Form.Item> */}

                            {selectedCategory === 'noc' && (
                                <Form.Item
                                    {...FORM_ITEM_LAYOUT} name="to_date" label="To Date" rules={[
                                        {
                                            required: true,
                                            message: 'To Date must not be empty',
                                        },
                                    ]}
                                >
                                    <DatePicker placeholder="Select date" style={{ width: '100%' }} />
                                </Form.Item>
                            )}
                            {selectedCategory === 'noc' && (
                                <Form.Item
                                    {...FORM_ITEM_LAYOUT} name="from_date" label="From Date" rules={[
                                        {
                                            required: true,
                                            message: 'From Date must not be empty',
                                        },
                                    ]}
                                >
                                    <DatePicker placeholder="Select date" style={{ width: '100%' }} />
                                </Form.Item>
                            )}
                            {/* </Col> */}

                            {/* <Col span={8}> */}
                            <Form.Item
                                {...FORM_ITEM_LAYOUT} name="issue_date" label="Requested Issue Date" rules={[
                                    {
                                        required: true,
                                        message: 'Issue Date must not be empty',
                                    },
                                ]}
                            >
                                <DatePicker placeholder="Select date" style={{ width: '100%' }} />
                            </Form.Item>
                            {selectedCategory === 'noc' && (
                                <Form.Item
                                    {...FORM_ITEM_LAYOUT}
                                    name="passport_no"
                                    label="Passport No"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Passport number must not be empty',
                                        },
                                        {
                                            max: 32,
                                            min: 4,
                                            message: 'Passport number must have a length between 4 and 32',
                                        },
                                    ]}
                                >
                                    <Input allowClear placeholder="Enter passport number" />
                                </Form.Item>
                            )}
                            {selectedCategory === 'noc' && (
                                <Form.Item
                                    {...FORM_ITEM_LAYOUT} name="intended_country" label="Intend Country" rules={[
                                        {
                                            required: true,
                                            message: 'Country must not be empty',
                                        },
                                    ]}
                                >
                                    <Input allowClear placeholder="Enter intended country" />
                                </Form.Item>
                            )}
                            <Form.Item
                                {...FORM_ITEM_LAYOUT}
                                name="description"
                                label="Description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Description must not be empty',
                                    },
                                ]}
                            >
                                <Input.TextArea allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

FormModal.propTypes = {
    visible: PropTypes.bool,
    formName: PropTypes.string,
    width: PropTypes.number,
    title: PropTypes.string,
    noc_type: PropTypes.array,
    category: PropTypes.array,
    handleCancel: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    handleOk: PropTypes.func.isRequired,
}

FormModal.defaultProps = {
    formName: 'dynamic_rule',
    title: 'form title',
    noc_type: [],
    category: [],
    visible: false,
    width: 550,
}

export default FormModal
